.not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: var(--core-pay-record-not-found-background);
}

.not-found *{
    line-height: 150%;
}

.not-found-content{
    min-width: 453px;
    height: 261px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    content: 'sorry';
    font-weight: 500;
    font-size: 32px;
}

.not-found-content::before{
    content: attr(before);
    font-weight: 900;
    font-size: 50px;
}

.not-found-content::after{
    content: attr(after);
    font-size: 25px;
}

.no-image{
    width: 420px;
}